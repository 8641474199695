<template>
  <div>
    <!-- <v-dialog v-model="showCheckoutDialog" max-width="400px" persistent>
      <v-card flat class="Card-style">
        <v-card-title style="color: blue"
          >Imformatory Message
          <v-spacer></v-spacer>
          <v-icon class="right" @click="showDialog = false">cancel</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="order-info pt-3">
            <span v-html="imformatoryText.instructions"></span>
          </div>

          <v-layout>
            <v-flex xs12>
              <v-card-title class="title text-xs-center pl-0 pb-3 primary--text"
                >Upload Documents</v-card-title
              >
              <v-flex>
                <v-text-field
                  placeholder="Select Image"
                  @click="pickFile"
                  @click:prepend="pickFile"
                  v-model="imageName"
                  append-icon="attach_file"
                  autocomplete="off"
                  outlined
                  rounded
                  dense
                  readonly
                ></v-text-field>
                <input
                  type="file"
                  accept="image/*"
                  ref="image"
                  @change="onFilePicked"
                  style="display: none"
                />
              </v-flex>
              <v-card-actions>
                <v-row align="center" justify="center">
                  <v-btn
                    @click="showDialog = false"
                    outlined
                    depressed
                    color="error"
                    >Cancel</v-btn
                  >
                  <v-btn
                    depressed
                    class="primary-btn ml-2"
                    @click="uploadDidAttachment"
                    outlined
                  >
                    Upload
                  </v-btn>
                </v-row>
              </v-card-actions>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="userFormDialog" max-width="900" persistent>
      <div>
        <v-card flat class="Card-style pa-2" :loading="submitLoader">
          <v-layout>
            <v-flex xs12 class="pa-2">
              <label class="title text-xs-center"
                >Upload Documents
                <span class="text-capitalize"
                  >For {{ getCountryName }}</span
                ></label
              >
              <v-icon style="float: right" class="right" @click="closeUserForm"
                >cancel</v-icon
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <v-container fluid>
            <v-radio-group
              v-model="radioGroup"
              label="Type:"
              row
              mandatory
              @change="changeForm(radioGroup)"
            >
              <v-radio label="Personal" value="PERSONAL"></v-radio>
              <v-radio label="Business" value="BUSINESS"></v-radio>
            </v-radio-group>
          </v-container>
          <v-col cols="12" class="pb-0">
            <!-- personal form -->
            <v-card-subtitle class="pl-0 pb-3 pt-2"
              ><b>Previous uploaded documents:-</b>
            </v-card-subtitle>
            <v-row>
              <v-col cols="12" sm="4" class="py-0">
                <v-select
                  label="Select Documents"
                  :items="getDocumentList"
                  v-model="document.documentInfoId"
                  item-text="name"
                  item-value="documentInfoId"
                  color="primary"
                  outlined
                  dense
                  hide-details
                >
                  <template slot="item" slot-scope="data">
                    <v-list-item-content @click="getItemText(data.item)">
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-card-subtitle class="pl-1 pb-3"
              ><b>Personal Information:-</b>
            </v-card-subtitle>
            <v-form ref="form" v-model="isFormValid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="First name"
                    v-model="userDocumentInfo.firstName"
                    color="primary"
                    :rules="firstNameRules"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Middle name"
                    v-model="userDocumentInfo.middleName"
                    color="primary"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Last name"
                    v-model="userDocumentInfo.lastName"
                    color="primary"
                    :rules="lastNameRules"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="userDocumentInfo.dateOfBirth"
                        label="Date Of Birth"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="userDocumentInfo.dateOfBirth"
                      no-title
                      :max="maxDate"
                      @input="dateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Personal Tax ID "
                    v-model="userDocumentInfo.personalTaxId"
                    color="primary"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Address"
                    v-model="userDocumentInfo.address"
                    color="primary"
                    :rules="addressRules"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="City"
                    v-model="userDocumentInfo.city"
                    :rules="cityRules"
                    color="primary"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Country"
                    v-model="userDocumentInfo.country"
                    :rules="countryRules"
                    color="primary"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="ZIP Code or Postal Code"
                    v-model="userDocumentInfo.postalCode"
                    color="primary"
                    :rules="postalCodeRules"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Country of Birth"
                    v-model="userDocumentInfo.countryOfBirth"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <div v-if="radioGroup == 'BUSINESS'">
                <v-card-subtitle class="pl-1 pb-3"
                  ><b>Business Information:- </b></v-card-subtitle
                >
                <v-row>
                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      label="Company name"
                      v-model="userDocumentInfo.companyName"
                      color="primary"
                      :rules="businessNameRules"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      label="Company registration number"
                      v-model="userDocumentInfo.companyRegistrationNumber"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      label="Incorportation Country"
                      v-model="userDocumentInfo.incorporationCountry"
                      color="primary"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      label="VAT ID"
                      v-model="userDocumentInfo.vatId"
                      color="primary"
                      outlined
                      hide-details
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-form>

            <v-card-subtitle class="pl-1 pb-3"
              ><b>Upload Documents:-</b>
            </v-card-subtitle>
            <!-- <v-row>
              <v-col cols="12" sm="4" class="py-0">
                <v-select
                  label="Select Document"
                  :items="documentList"
                  item-text="text"
                  item-value="value"
                  v-model="fileUpload.uploadFileKey"
                  color="primary"
                  outlined
                  @change="uploadFiles()"
                  dense
                  clearable
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-file-input
                  label="upload"
                  color="primary"
                  append-icon="mdi mdi-paperclip"
                  v-model="fileUpload.uploadFile"
                  outlined
                  @change="uploadFiles"
                  dense
                >
                </v-file-input>
              </v-col>
            </v-row> -->
            <v-form>
              <v-row v-for="(input, index) in uploadInfoInputs" :key="index">
                <v-col cols="12" sm="4" class="py-0">
                  <v-select
                    label="Select Document"
                    :items="documentList"
                    item-text="text"
                    item-value="value"
                    v-model="input.uploadFileKey"
                    color="primary"
                    outlined
                    @change="uploadFiles(input.uploadFileKey)"
                    dense
                    clearable
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-file-input
                    label="upload"
                    color="primary"
                    append-icon="mdi mdi-paperclip"
                    v-model="input.uploadFile"
                    :rules="FileUploadrules"
                    outlined
                    @change="uploadFiles(input.uploadFile)"
                    dense
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-btn fab outlined small color="primary" @click="addRow">
                    <v-icon> add </v-icon></v-btn
                  >
                  <v-btn
                    v-if="uploadInfoInputs.length != 1"
                    fab
                    outlined
                    class="ml-3"
                    small
                    color="error"
                    @click="deleteRow(index)"
                    ><v-icon>delete</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="error"
                elevation="0"
                class="mt-2"
                @click="closeUserForm"
                >Cancel</v-btn
              >
              <v-btn
                depressed
                color="primary"
                class="mt-2"
                :loading="submitLoader"
                @click="submitUserForm()"
                elevation="0"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-card>
      </div>
    </v-dialog>

    <div>
      <v-row>
        <v-col lg="12" md="12" sm="12">
          <v-card elevation="1">
            <v-card-title>Order</v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="orderHeaders"
              :items="orderDetail"
              :hide-default-footer="true"
            >
              <template v-slot:[`item.orderDate`]="{ item }">
                {{ moment(item.orderDate).format("MMMM Do YYYY, h:mm:ss a") }}
              </template>
              <template v-slot:[`item.orderStatus`]="{ item }">
                {{ item.orderStatus === 'Created' ? "Renewed" : item.orderStatus }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" lg="12">
          <v-card elevation="1">
            <v-card-title>Number Details</v-card-title>
            <v-divider></v-divider>
            <v-data-table :headers="headers" :items="orderDetailList">
              <template v-slot:[`item.number`]="{ item }">
                {{ item.number ? item.number : "xxxxxxx" }}
              </template>
              <template v-slot:[`item.itemId`]="{ item }">
                {{ capitalizeText(item.itemId ? item.itemId : "") }}
              </template>
              <template v-slot:[`item.didStatus`]="{ item }">
                <span v-if="item.didStatus">
                  {{ capitalizeText(item.didStatus.replace("DID_", "")) }}
                </span>
                <span v-else>
                  Cancelled
                </span>
              </template>
              <template v-slot:[`item.didType`]="{ item }">
                {{ capitalizeText(item.didType ? item.didType : "") }}
              </template>
              <template v-slot:[`item.stateName`]="{ item }">
                {{ item.stateName ? item.stateName : "----" }}
              </template>
              <template v-slot:[`item.unitPrice`]="{ item }">
                ${{ item.unitPrice }}
              </template>
              <template v-slot:[`item.adjustmentCost`]="{ item }">
                ${{ item.adjustmentCost }}
              </template>
              <template v-slot:[`item.itemTotal`]="{ item }">
                {{ item.itemTotal ? "$" + item.itemTotal : "-" }}
              </template>
              <template v-slot:[`item.billingTenurePeriod`]="{ item }">
                {{
                  item.billingTenurePeriod
                    ? item.billingTenurePeriod + " Month"
                    : "-"
                }}
              </template>
              <!-- <template v-slot:[`item.uploadAction`]="{ item }">
                <v-icon
                  @click="viewDocument(item)"
                  color="blue"
                  v-if="item.requireDocument == 'Y'"
                  >cloud_upload</v-icon
                >
                <v-icon v-if="item.requireDocument !== 'Y'"
                  >indeterminate_check_box</v-icon
                >
              </template> -->
            </v-data-table>
            <loading :active="isLoading" :loader="loader" />
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import orderAPI from "@/services/orderAPI.js";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      countryNames: "",
      radioGroup: "PERSONAL",
      cartList: [],
      accountBillingList: [],
      grandTotal: 0,
      totalDIDNumbers: 0,
      instructions: [],
      billingAccountId: null,
      showCheckoutDialog: false,
      removeCartData: {},
      userFormDialog: false,
      userDetail: {},
      isLoading: false,
      loader: "bars",
      userDocumentInfo: {
        documentInfoId: "",
      },
      getDocumentList: [],
      documentList: [
        {
          value: "BUS_REG_CER",
          text: "Business Registration Certificate",
        },
        {
          value: "CO_REN_CER",
          text: "Company renewal Certificate",
        },
        {
          value: "PASSPORT",
          text: "Passport",
        },
        {
          value: "NAT_ID_PRF",
          text: "National ID proof",
        },
        {
          value: "DL",
          text: "Driving Licence",
        },
        {
          value: "OTH_ID_PRF",
          text: "Other ID Proof",
        },

        {
          value: "UTL_BILL",
          text: "Utility Bill",
        },
        {
          value: "TELCOM_BILL",
          text: "Telecom Bill",
        },
        {
          value: "RE_BANK_ST",
          text: "Recent Bank Statement",
        },
        {
          value: "RENT_LEASE_AGT",
          text: "Rent//Lease Agreement",
        },
        {
          value: "OTH_ADDRESS_PRF",
          text: "other Address proof",
        },
        {
          value: "OTHERS",
          text: "Others",
        },
      ],
      isFormValid: true,
      submitLoader: false,
      firstNameRules: [(v) => !!v || "Field is required"],
      lastNameRules: [(v) => !!v || "Field is required"],
      addressRules: [(v) => !!v || "Field is required"],
      cityRules: [(v) => !!v || "Field is required"],
      countryRules: [(v) => !!v || "Field is required"],
      postalCodeRules: [(v) => !!v || "Field is required"],
      businessNameRules: [(v) => !!v || "Field is required"],
      FileUploadrules: [
        (v) => !v || v.size < 5000000 || "file size should be less than 5 Mb!",
      ],
      uploadInfoInputs: [],
      dateMenu: "",
      maxDate: "",
      uploadFile: {},
      document: {},
      sumGetTotalLength: 0,
      getTotalFormLength: [],
      flag: "",
      // showNextButton: false,
      // showSubmitButton: true,
      geoIdFordocumentInfoList: "",
      base64Data: "",
      updateIdNames: "",
      uploadedFiles: [],
      countryNameList: [],
      documentInfoList: [],
      showIndex: 0,
      countryGeoId: "",
      FormType: "PERSONAL",
      geoIDSelected: "",
      // shoping cart end items
      moment: moment,
      orderDetail: [],
      orderDetailList: [],
      imformatoryText: "",
      inventoryItemId: "",
      fileUpload: {},
      headers: [
        {
          text: "Number",
          value: "number",
          sortable: false,
        },
        {
          text: "Item ID",
          value: "itemId",
          align: "center",
          sortable: false,
        },
        {
          text: "DID Status",
          value: "didStatus",
          align: "center",
          sortable: false,
        },
        {
          text: "DID Type",
          value: "didType",
          align: "center",
          sortable: false,
        },
        {
          text: "Country",
          value: "countryName",
          align: "center",
          sortable: false,
        },
        {
          text: "State",
          value: "stateName",
          align: "center",
          sortable: false,
        },
        {
          text: "City",
          value: "cityName",
          align: "center",
          sortable: false,
        },
        {
          text: "Quantity",
          value: "quantity",
          align: "center",
          sortable: false,
        },
        {
          text: "Unit Price",
          value: "unitPrice",
          align: "center",
          sortable: false,
        },
        {
          text: "Setup Cost",
          value: "adjustmentCost",
          align: "center",
          sortable: false,
        },
        {
          text: "Item Total",
          value: "itemTotal",
          align: "center",
          sortable: false,
        },
        {
          text: "Billing Tenure",
          value: "billingTenurePeriod",
          align: "center",
          sortable: false,
        },
        // {
        //   text: "Upload Document",
        //   value: "uploadAction",
        //   align: "center",
        //   sortable: false,
        // },
      ],
      orderHeaders: [
        {
          text: "Order ID",
          value: "orderId",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          value: "orderStatus",
          align: "center",
          sortable: false,
        },
        {
          text: "Order Date",
          value: "orderDate",
          align: "center",
          sortable: false,
        },
        {
          text: "Grand Total",
          value: "grandTotal",
          align: "center",
          sortable: false,
        },
      ],
      imageName: "",
      imageUrl: "",
      imageFile: "",
      type: "",
      getinventoryItemId: "",
      getOrderId: "",
      getGeoIdForDoc: "",
      orderId: "",
      getCountryName: "",
      quantityGetFromOrder: "",
      getfileName: "",
      getuploadedFile: "",
      docinfoID: "",
    };
  },

  methods: {
    changeForm(formName) {
      if (formName) {
        this.radioGroup = formName;
        this.getDocumentInfo(formName);
      } else {
        this.getDocumentInfo(formName);
        this.radioGroup = formName;
      }
    },

    closeUserForm() {
      this.userFormDialog = false;
      //this.uploadFile = {};
      this.userDocumentInfo.documentInfoId = {};
      this.userDocumentInfo = {};
      this.document = {};
      this.fileUpload = {};
      this.$refs.form.reset();
    },

    getGeoID(geoIdRetrive) {
      this.geoIDSelected = geoIdRetrive;
    },

    updateValueData(value) {
      //this.$refs.form[0].validate()
    },

    valueChangeCheck(idName) {
      this.updateIdNames = idName;
    },

    addRow() {
      this.uploadedFiles = [];
      if (this.uploadInfoInputs.length < 6) {
        this.uploadInfoInputs.push({
          uploadFileKey: null,
          uploadFile: null,
        });
      } else {
        if (this.uploadInfoInputs.length != 0) {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Maximum add six documents!",
            color: "error",
          });
        }
      }
    },

    deleteRow(index) {
      this.uploadedFiles = [];
      this.uploadInfoInputs.splice(index, 1);
      //this.getCartItem();
    },

    getItemText(selectedDocumentData) {
      this.userDocumentInfo = selectedDocumentData;
      this.getTotalFormLength = Object.values(this.userDocumentInfo);
    },

    async viewDocument(item) {
      this.userFormDialog = true;
      this.inventoryItemId = item.inventoryItemId;
      this.getGeoIdForDoc = item.facilityGeoId;
      this.getCountryName = item.countryName;
      this.quantityGetFromOrder = item.quantity;
      this.getDocumentInfo(this.FormType);
    },

    async getDocumentInfo(formName) {
      this.isLoading = true;
      var getDocumentList = [];
      this.getDocumentList = [];
      try {
        let response = await orderAPI.getDocumentInfoApi({
          partyId: this.userDetail.partyId,
          geoId: this.getGeoIdForDoc,
          documentInfoType: formName,
        });
        getDocumentList = response.documentInfoList;

        var userDocumentInfo = [];
        for (let index = 0; index < getDocumentList.length; index++) {
          var element = getDocumentList[index];
          if (element.firstName) {
            element.name =
              element.firstName + " " + "(" + element.country + ")";
          }
          userDocumentInfo.push(element);
        }
        this.getDocumentList = userDocumentInfo;
        this.userFormDialog = true;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },

    // const element = this.fileUpload;
    // if (element.uploadFileKey)
    //   if (element.uploadFile) {
    //     console.log(element.uploadFile);
    //     this.uploadFile["mimeType"] = element.uploadFile.type;
    //     var files = [];
    //     var this_ = this;
    //     files.push(element.uploadFile);
    //     if (files.length > 0) {
    //       var reader = new FileReader();
    //       var file = "updating";
    //       reader.onloadend = function (evt) {
    //         var data = evt.target.result;
    //         file = data;
    //         file = data.split(",");
    //         // console.log("file", file);
    //         var rawData = file[1];
    //         this_.uploadFile["uploadedFile"] = rawData;
    //         this_.uploadFile["fileName"] = element.uploadFileKey;
    //       };
    //       reader.readAsDataURL(files[0]);
    //     }
    //   }

    //upload document functionality
    uploadFiles(data) {
      this.uploadFile = {};
      this.uploadedFiles = [];
      if (this.uploadInfoInputs.length != 0) {
        for (let index = 0; index < this.uploadInfoInputs.length; index++) {
          const element = this.uploadInfoInputs[index];
          element.uploadFiles = {};
          var rawData = [];
          var this_ = this;
          var files = [];
          //  making baseUrl of upload documents
          if (element.uploadFileKey != null) {
            files.push(element.uploadFile);
            var reader = new FileReader();
            var file = "updating";
            reader.onloadend = function (evt) {
              var data = evt.target.result;
              file = data.split(",");
              rawData = file[1];
              if (
                element.uploadFileKey != null ||
                element.uploadFileKey != undefined
              ) {
                element.uploadFiles["uploadedFile"] = rawData;
                element.uploadFiles["fileName"] = element.uploadFileKey;
                element.uploadFiles["mimeType"] = element.uploadFile.type;
              }
              this_.uploadedFiles.push(element.uploadFiles);
            };
            if (files[0]) {
              reader.readAsDataURL(files[0]);
            }
          }
        }
      }
    },

    async submitUserForm() {
      var fillForm = null;
      var userDocumentInfoArray = Object.values(this.userDocumentInfo);
      if (this.getTotalFormLength.length != 0) {
        for (var i = 0; i < this.getTotalFormLength.length; i++) {
          if (this.getTotalFormLength[i] != userDocumentInfoArray[i]) {
            fillForm = true;
          } else if (this.getTotalFormLength[i] == userDocumentInfoArray[i]) {
            if (fillForm != true) {
            }
          }
        }
      } else {
        fillForm = true;
      }
      //part Id
      this.userDocumentInfo.partyId = this.userDetail.partyId;
      this.userDocumentInfo.geoId = this.getGeoIdForDoc;
      if (this.$refs.form.validate()) {
        if (this.uploadedFiles.length != 0) {
          if (this.userDocumentInfo.dateOfBirth) {
            this.formatDate(this.userDocumentInfo.dateOfBirth);
          }
          if (fillForm == true) {
            if (this.userDocumentInfo.documentInfoId) {
              delete this.userDocumentInfo.documentInfoId;
            }
            this.userDocumentInfo["documentInfoType"] = this.radioGroup;

            this.submitLoader = true;
            try {
              let response = await orderAPI.uploadDocumentInfo(
                this.userDocumentInfo
              );
              this.document.documentInfoId = response.outMap.documentInfoId;
              this.showIndex++;
              this.uploadDidAttachment();
              fillForm = null;
              this.$root.$emit("SHOW_SNACKBAR", {
                text: response.responseMessage,
                color: "success",
              });
              this.submitLoader = false;
              this.userFormDialog = false;
              this.userDocumentInfo.documentInfoId = {};
              this.userDocumentInfo = {};
              this.document = {};
              this.uploadInfoInputs = [
                {
                  uploadFileKey: null,
                  uploadFile: null,
                },
              ];
              this.$refs.form.reset();
            } catch (error) {
              this.$root.$emit("SHOW_SNACKBAR", {
                text: "something wrong",
                color: "error",
              });
              this.submitLoader = false;
            }
          } else {
            if (this.userDocumentInfo.documentInfoId) {
              this.submitLoader = true;
              this.orderDetail.forEach((element) => {
                this.orderDetail.orderId = element.orderId;
              });
              try {
                let response = await orderAPI.setInventoryItemDocInfo({
                  orderId: this.orderDetail.orderId,
                  geoId: this.getGeoIdForDoc,
                  inventoryItemId: this.inventoryItemId,
                  documentInfoId: this.userDocumentInfo.documentInfoId,
                });
                this.document.documentInfoId = response.outMap.documentInfoId;
                this.showIndex++;
                this.uploadInfoInputs = [
                  {
                    uploadFileKey: null,
                    uploadFile: null,
                  },
                ];
                this.uploadDidAttachment();
                fillForm = null;
                this.userDocumentInfo.documentInfoId = {};
                this.userDocumentInfo = {};
                this.document = {};
                this.$refs.form.reset();
                this.$root.$emit("SHOW_SNACKBAR", {
                  text: response.responseMessage,
                  color: "success",
                });
                this.submitLoader = false;
              } catch (error) {
                this.$root.$emit("SHOW_SNACKBAR", {
                  text: "something wrong",
                  color: "error",
                });
                this.submitLoader = false;
              }
            }
          }
        } else {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Please upload documents!",
            color: "error",
          });
        }
      }
    },

    formatDate(SelectedDate) {
      var date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      this.userDocumentInfo.dateOfBirth = `${SelectedDate} ${hours}:${minutes}:${seconds}`;
      // console.log(
      //   "this.userDocumentInfo.dateOfBirth",
      //   this.userDocumentInfo.dateOfBirth
      // );
    },

    closeCheckOutDialog() {
      this.$root.$emit("SHOW_SNACKBAR", {
        text: "Your order is placed",
        color: "success",
      });
    },

    async getOrderDetail() {
      this.isLoading = true;
      try {
        let response = await orderAPI.getOrderDetail(
          this.$route.params.orderId
        );
        this.isLoading = false;
        this.orderDetail.push(response.orderDetails);
        this.orderDetailList = response.orderDetails.orderItemList;
        this.orderInventoryItemId = {
          ...this.orderDetailList,
          ...response.orderDetails.orderItemList,
        };
      } catch (error) {
        this.isLoading = false;
      }
    },

    capitalizeText(str) {
      str = str.replace("_", " ").toLowerCase();
      str = str.split(" ");
      let newStr = "";
      for (let text of str) {
        text = text.charAt(0).toUpperCase() + text.slice(1);
        newStr += ` ${text}`;
      }
      // console.log("newStr", newStr);
      return newStr;
    },

    async uploadDidAttachment() {
      this.orderDetail.forEach((element) => {
        this.getOrderId = element.orderId;
      });
      this.isLoading = true;
      let uploadedData = {
        inventoryItemId: this.inventoryItemId,
        orderId: this.getOrderId,
        partyId: this.userDetail.partyId,
        dataCategoryId: "LEGAL",
        contentTypeId: "OUTLINE_NODE",
        statusId: "DOC_UNAPPROVED",
        didContentTypeId: "CTNT_FINAL_DRAFT",
        roleTypeId: "OWNER",
        geoId: this.getGeoIdForDoc,
      };
      for (let i = 0; i < this.uploadedFiles.length; i++) {
        //const element = this.uploadedFiles[i];
        if (this.uploadedFiles[i].mimeType) {
          uploadedData.mimeTypeId = this.uploadedFiles[i].mimeType;
        }
        if (this.uploadedFiles[i].fileName) {
          uploadedData.fileName = this.uploadedFiles[i].fileName;
        }

        if (this.uploadedFiles[i].uploadedFile) {
          uploadedData.uploadedFile = this.uploadedFiles[i].uploadedFile;
        }
        try {
          let response = await orderAPI.uploadDidAttachment(uploadedData);
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Document uploaded successfully",
            color: "success",
          });
          if ((response.responseMessage = "success")) {
            this.submitLoader = false;
            this.isLoading = false;
            this.userFormDialog = false;
            this.uploadFile = {};
            this.userDocumentInfo.documentInfoId = {};
            this.userDocumentInfo = {};
            this.document = {};
            this.fileUpload = {};
            this.$refs.form.reset();
          }
        } catch (error) {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: error.data.messageDetail,
            color: "error",
          });
          this.isLoading = false;
          this.submitLoader = false;
        }
      }
    },
  },

  mounted() {
    this.addRow();
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.getOrderDetail();
  },
};
</script>